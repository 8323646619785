import React, { useRef, useEffect } from "react";
import { Icon } from "@iconify/react";
import { useCart } from "react-use-cart";
import CartItem from "./CartItem";
import { NavLink } from "react-router-dom";

function SideCart({ sideCartOpen, setSideCartOpen }) {
  const trigger = useRef(null);
  const sidebar = useRef(null);

  const { totalUniqueItems, items, cartTotal, updateItemQuantity, removeItem } = useCart();

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (!sideCartOpen || sidebar.current.contains(target) || trigger.current.contains(target)) return;
      setSideCartOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  return (
    <div className="absolute inset-0 overflow-x-hidden">
      {/* SideCart backdrop */}
      <div
        className={`fixed inset-0 bg-slate-900 bg-opacity-50 z-40 transition-opacity duration-200 ${
          sideCartOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        aria-hidden="true"
      ></div>

      {/* SideCart */}
      <div
        id="sidecart"
        ref={sidebar}
        className={`flex flex-col absolute z-40 right-0 top-0 transform h-screen w-full md:w-96 shrink-0 bg-slate-800 transition-all duration-200 ease-in-out ${
          sideCartOpen ? "translate-x-0" : "translate-x-full md:translate-x-96"
        }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between p-4">
          {/* Close button */}
          <button
            ref={trigger}
            className="text-slate-500 hover:text-slate-400 flex items-center space-x-2"
            onClick={() => setSideCartOpen(!sideCartOpen)}
            aria-controls="sidebar"
            aria-expanded={sideCartOpen}
          >
            <Icon icon="tabler:x" className="w-8 h-8" />
            <span>Close</span>
          </button>
        </div>

        {/* Content */}
        <div className="flex-1 overflow-y-scroll no-scrollbar p-4">
          {/* Pages group */}
          <h4 className="font-semibold text-xl text-white mb-4 font-bungee">My Cart ({totalUniqueItems})</h4>

          <div className="space-y-4 divide-y divide-slate-700">
            {items.map((item) => (
              <CartItem
                trigger={trigger}
                key={item.id}
                quantity={item.quantity}
                title={item.name}
                price={item.price}
                image={item.image}
                onAddQuantity={() => updateItemQuantity(item.id, item.quantity + 1)}
                onSubQuantity={() => updateItemQuantity(item.id, item.quantity - 1)}
                onRemoveItem={() => removeItem(item.id)}
              />
            ))}
          </div>
        </div>

        {/* Checkout */}
        <div className="bg-slate-800 border-t-2 border-white p-4">
          <div className="text-white space-y-2">
            <div className="flex justify-between items-center mb-4 font-bold">
              <span>Total</span>
              <h6>${cartTotal}</h6>
            </div>
          </div>
          <NavLink to="/checkout">
            <button className="w-full rounded p-3 uppercase bg-white font-bold text-sm">Checkout</button>
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default SideCart;
