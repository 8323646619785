import { Icon } from "@iconify/react";
import React, { useState, useEffect, useRef } from "react";
import { useMoralis } from "react-moralis";
import { NavLink, useLocation } from "react-router-dom";

function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const location = useLocation();
  const { pathname } = location;
  const { logout } = useMoralis();

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
  const [sidebarExpanded, setSidebarExpanded] = useState(storedSidebarExpanded === null ? true : storedSidebarExpanded === "true");

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (!sidebarOpen || sidebar.current.contains(target) || trigger.current.contains(target)) return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector("body").classList.add("sidebar-expanded");
    } else {
      document.querySelector("body").classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  const handleLogOut = async () => {
    localStorage.removeItem("isAuthenticated");
    await logout();
  };

  return (
    <div>
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-[#196052] bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 border-r-4 border-black ${
          sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        aria-hidden="true"
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 bg-[#196052] p-4 transition-all duration-200 ease-in-out border-r-4 border-black ${
          sidebarOpen ? "translate-x-0" : "-translate-x-64"
        }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between mb-10 pr-3 sm:px-2">
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-slate-500 hover:text-slate-400"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}
          <NavLink end to="/" className="block -mt-8">
            <img src="/images/logo/logo-navbar.webp" />
          </NavLink>
        </div>

        {/* Links */}
        <div className="space-y-8 font-bungee">
          {/* Pages group */}
          <div>
            <h3 className="text-xs uppercase text-white/40 font-semibold pl-3">
              <span className="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6" aria-hidden="true">
                •••
              </span>
              <span className="lg:hidden lg:sidebar-expanded:block 2xl:block">Pages</span>
            </h3>
            <ul className="mt-3 space-y-2">
              {/* Home */}
              <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname === "/dashboard" && "bg-[#196052]"}`}>
                <NavLink
                  end
                  to="/dashboard"
                  className={`block hover:text-white truncate transition duration-150 ${pathname === "/dashboard" ? "text-white" : "text-white/60"}`}
                >
                  <div className="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path
                        className={`${pathname === "/dashboard" ? "text-primary-300" : "text-white/40"}`}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                      />
                    </svg>
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Home</span>
                  </div>
                </NavLink>
              </li>
              {/* Collections */}
              <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname === "/collections" && "bg-[#196052]"}`}>
                <NavLink
                  end
                  to="/collections"
                  className={`block hover:text-white truncate transition duration-150 ${pathname === "/collections" ? "text-white" : "text-white/60"}`}
                >
                  <div className="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path
                        className={`${pathname === "/collections" ? "text-primary-300" : "text-white/40"}`}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                      />
                    </svg>
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Collections</span>
                  </div>
                </NavLink>
              </li>
              {/* Merchandise */}
              <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname === "/merchandise" && "bg-[#196052]"}`}>
                <NavLink
                  end
                  to="/merchandise"
                  className={`block hover:text-white truncate transition duration-150 ${pathname === "/merchandise" ? "text-white" : "text-white/60"}`}
                >
                  <div className="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path
                        className={`${pathname === "/merchandise" ? "text-primary-300" : "text-white/40"}`}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                      />
                    </svg>
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Merchandise</span>
                  </div>
                </NavLink>
              </li>
              {/* Digital Goodies */}
              <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname === "/digital-goodies" && "bg-[#196052]"}`}>
                <NavLink
                  end
                  to="/digital-goodies"
                  className={`block hover:text-white truncate transition duration-150 ${pathname === "/digital-goodies" ? "text-white" : "text-white/60"}`}
                >
                  <div className="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path
                        className={`${pathname === "/digital-goodies" ? "text-primary-300" : "text-white/40"}`}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"
                      />
                    </svg>
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Digital Goodies</span>
                  </div>
                </NavLink>
              </li>
              {/* Voucher Generator */}
              {/* <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname === "/voucher-generator" && "bg-[#196052]"}`}>
                <NavLink
                  end
                  to="/voucher-generator"
                  className={`block hover:text-white truncate transition duration-150 ${
                    pathname === "/voucher-generator" ? "text-white" : "text-white/60"
                  }`}
                >
                  <div className="flex items-center">
                    <Icon icon="heroicons-outline:cube-transparent" className="shrink-0 h-6 w-6" />
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Voucher Generator
                    </span>
                  </div>
                </NavLink>
              </li> */}
              {/* Self-Generated Ticket */}
              <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname === "/self-ticket" && "bg-[#196052]"}`}>
                <NavLink
                  end
                  to="/self-ticket"
                  className={`block hover:text-white truncate transition duration-150 ${pathname === "/self-ticket" ? "text-white" : "text-white/60"}`}
                >
                  <div className="flex items-center">
                    <Icon
                      icon="heroicons-outline:ticket"
                      className={`shrink-0 h-6 w-6 ${pathname === "/self-ticket" ? "text-primary-300" : "text-white/40"}`}
                    />
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      Self-Generated Ticket
                    </span>
                  </div>
                </NavLink>
              </li>
              {/* Logout */}
              <li className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${pathname === "/logout" && "bg-[#196052]"}`}>
                <NavLink
                  end
                  to="/"
                  onClick={() => handleLogOut()}
                  className={`block hover:text-white truncate transition duration-150 ${pathname === "/logout" ? "text-white" : "text-white/60"}`}
                >
                  <div className="flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" className="shrink-0 h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path
                        className={`${pathname === "/logout" ? "text-primary-300" : "text-white/40"}`}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                      />
                    </svg>
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">Logout</span>
                  </div>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>

        {/* Expand / collapse button */}
        <div className="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
          <div className="px-3 py-2">
            <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
              <span className="sr-only">Expand / collapse sidebar</span>
              <svg className="w-6 h-6 fill-current sidebar-expanded:rotate-180" viewBox="0 0 24 24">
                <path className="text-slate-400" d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z" />
                <path className="text-slate-600" d="M3 23H1V1h2z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
