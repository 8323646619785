import React, { useEffect } from "react";
import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";

import "./css/main.css";
// import './charts/ChartjsConfig';

// Import pages
import Home from "./pages/Home";
import Collections from "./pages/Collections";
import CollectionsDetail from "./pages/CollectionsDetail";
import Merchandise from "./pages/Merchandise";
import DigitalGoodies from "./pages/DigitalGoodies";
import useLogin from "./web3/Authentication";
import Auth from "./pages/Auth";
import MintPage from "./pages/MintPage";
import VoucherGenerator from "./pages/VoucherGenerator";
import Profile from "./pages/Profile";
import LandingPage from "./pages/LandingPage";
import SelfGeneratedTicket from "./pages/SelfGeneratedTicket";
import Checkout from "./pages/Checkout";

function App() {
  const location = useLocation();

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route
          exact
          path="/dashboard"
          element={
            <RequireAuth>
              <Home />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/mint"
          element={
            <RequireAuth>
              <MintPage />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/collections"
          element={
            <RequireAuth>
              <Collections />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/collections/:id"
          element={
            <RequireAuth>
              <CollectionsDetail />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/merchandise"
          element={
            <RequireAuth>
              <Merchandise />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/digital-goodies"
          element={
            <RequireAuth>
              <DigitalGoodies />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/voucher-generator"
          element={
            <RequireAuth>
              <VoucherGenerator />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/self-ticket"
          element={
            <RequireAuth>
              <SelfGeneratedTicket />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/checkout"
          element={
            <RequireAuth>
              <Checkout />
            </RequireAuth>
          }
        />
        <Route
          exact
          path="/profile"
          element={
            <RequireAuth>
              <Profile />
            </RequireAuth>
          }
        />
        <Route exact path="/auth" element={<Auth />} />
      </Routes>
    </>
  );
}

export default App;

function RequireAuth({ children }) {
  const location = useLocation();
  const { isAuthenticated } = useLogin();

  useEffect(() => {
    setTimeout(() => {
      localStorage.setItem("isAuthenticated", isAuthenticated);
    }, 3000);
  }, [isAuthenticated]);

  const localAuth = localStorage.getItem("isAuthenticated");

  if (localAuth != 'true') {
    return <Navigate to="/auth" state={{ from: location }} replace />;
  }
  
  return children;
}
