import useLogin from "../web3/Authentication";
import toast, { Toaster } from "react-hot-toast";
import { Icon } from "@iconify/react";
import { useLocation, Navigate } from "react-router-dom";

function Auth() {
  const location = useLocation();
  const { Web3Login, isMetamaskInstalled, isAuthenticated, User } = useLogin();
  const isPhantomInstalled = window.solana && window.solana.isPhantom;

  if (isAuthenticated) {
    return <Navigate to="/dashboard" state={{ from: location }} replace />;
  }

  const handleWithoutMetamask = () => {
    toast.error("Metamask Extension not Installed!", { position: "top-right" });
  };

  const handleWithoutPhantom = () => {
    toast.error("Phantom Wallet Extension not Installed!", { position: "top-right" });
  };

  const handleWeb3Auth = (provider) => {
    try {
      Web3Login(provider).then(() => {
        localStorage.setItem("isAuthenticated", true);
        window.location.reload();
      });
    } catch ($e) {
      toast.error("Authentication Failed!", { position: "top-right" });
    }
  };

  return (
    <>
      <div className="w-full h-screen flex justify-center items-center bg-cover bg-center " style={{ backgroundImage: `url('/images/bg/bg-char.webp')` }}>
        <div className="flex-1 max-w-lg group">
          <img src="/images/logo/logo-green.webp" alt="Logo" className="mx-auto h-48 -mb-10 group-hover:-mb-6 transition-all" />
          <div className="bg-white p-12 shadow-lg rounded-lg">
            <h1 className="font-bold text-center text-2xl mb-6">Please Sign In to Continue</h1>
            {isPhantomInstalled ? (
              <button
                className="flex justify-center items-center space-x-2 w-full p-3 rounded text-white bg-[#4E44CE] mb-2 hover:opacity-75"
                onClick={() => handleWeb3Auth("Phantom")}
              >
                <Icon icon="clarity:shield-line" />
                <span className="font-medium">
                  Login with <strong>Phantom</strong>
                </span>
              </button>
            ) : (
              <button
                className="flex justify-center items-center space-x-2 w-full p-3 rounded text-white bg-[#4E44CE] mb-2 hover:opacity-75"
                onClick={() => handleWithoutPhantom()}
              >
                <Icon icon="clarity:shield-line" />
                <span className="font-medium">
                  Login with <strong>Phantom</strong>
                </span>
              </button>
            )}
            {isMetamaskInstalled ? (
              <button
                className="flex justify-center items-center space-x-2 w-full p-3 rounded text-white bg-[#f5841f] mb-2 hover:opacity-75"
                onClick={() => handleWeb3Auth("Metamask")}
              >
                <Icon icon="logos:metamask-icon" />
                <span className="font-medium">Login with Metamask</span>
              </button>
            ) : (
              <button
                className="flex justify-center items-center space-x-2 w-full p-3 rounded text-white bg-[#f5841f] mb-2 hover:opacity-75"
                onClick={() => handleWithoutMetamask()}
              >
                <Icon icon="logos:metamask-icon" />
                <span className="font-medium">
                  Login with <strong>Metamask</strong>
                </span>
              </button>
            )}
            <button
              className="flex justify-center items-center space-x-2 w-full p-3 rounded text-white bg-[#0b65c6] mb-2 hover:opacity-75"
              onClick={() => handleWeb3Auth("TrustWallet")}
            >
              <Icon icon="clarity:shield-line" />
              <span className="font-medium">
                Login with <strong>TrustWallet</strong>
              </span>
            </button>
          </div>
        </div>
      </div>
      <Toaster />
    </>
  );
}

export default Auth;
