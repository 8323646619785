import { useRef, useState, useEffect } from "react";
import LayoutBase from "../layouts/LayoutBase";
import useNft from "../web3/Nft";
import toast, { Toaster } from "react-hot-toast";
import { QrReader } from "react-qr-reader";
import { Icon } from "@iconify/react";

function MintPage() {
  const { Mint } = useNft();
  const [isScan, setIsScan] = useState(false);

  const quantityToMint = useRef("");
  const quantityAllowedToMint = useRef("");
  const nonce = useRef("");
  const voucher = useRef("");

  const mintHandler = (event) => {
    event.preventDefault();

    Mint({
      signedQty: quantityAllowedToMint.current.value,
      nonce: nonce.current.value,
      signature: voucher.current.value,
    });

    toast.success("Success minted NFT!", { position: "top-right" });
  };

  const scanHandler = (result, error) => {
    if (!!result) {
      try {
        const parseData = JSON.parse(result?.text);
        quantityAllowedToMint.current.value = parseData._signedQty;
        nonce.current.value = parseData._nonce;
        voucher.current.value = parseData._signature;
  
        setIsScan(false);
        toast.success("Success read data!", { position: "top-right" });
      } catch (error) {
        setIsScan(false);
        toast.error("QR not valid!", { position: "top-right" });
      }
    }
  };

  return (
    <LayoutBase>
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <h1 className="font-bold text-2xl mb-4 font-bungee">Mint</h1>
        <div className="max-w-2xl bg-white p-4 md:p-6 rounded shadow">
          <div>
            {isScan && (
              <>
                <QrReader onResult={scanHandler} style={{ width: "100%" }} />
              </>
            )}

            <button
              onClick={() => setIsScan(!isScan)}
              className="inline-flex items-center space-x-2 bg-amber-400 px-4 py-2 text-gray-800 rounded hover:opacity-90"
            >
              <Icon icon="heroicons-outline:qrcode" />
              <span>Scan QR</span>
            </button>
          </div>
          <hr className="my-4" />
          <form onSubmit={mintHandler} className="space-y-4">
            <div className="space-y-2">
              <label htmlFor="quantityToMint">Quantity To Mint</label>
              <input ref={quantityToMint} required id="quantityToMint" type="number" className="block w-full border-gray-300 rounded" placeholder="...." />
            </div>
            <div className="space-y-2">
              <label htmlFor="quantityAllowedToMint">Quantity Allowed To Mint</label>
              <input
                ref={quantityAllowedToMint}
                required
                id="quantityAllowedToMint"
                type="number"
                className="block w-full border-gray-300 rounded"
                placeholder="...."
              />
            </div>
            <div className="space-y-2">
              <label htmlFor="nonce">Nonce</label>
              <input ref={nonce} required id="nonce" type="number" className="block w-full border-gray-300 rounded" placeholder="...." />
            </div>
            <div className="space-y-2">
              <label htmlFor="voucher">Voucher</label>
              <input ref={voucher} required id="voucher" type="text" className="block w-full border-gray-300 rounded" placeholder="...." />
            </div>
            <button type="submit" className="px-6 py-2 bg-primary-800 text-white rounded hover:opacity-90">
              Mint
            </button>
          </form>
        </div>
      </div>

      <Toaster />
    </LayoutBase>
  );
}

export default MintPage;
