import { useState } from "react";
import { Icon } from "@iconify/react";
import useTimer from "easytimer-react-hook";

function LandingPage() {
  const [open, setOpen] = useState();

  const [timer, isTargetAchieved] = useTimer({
    countdown: true,
    startValues: {
      days: 2,
      hours: 5,
      minutes: 1,
      seconds: 10,
    },
    target: {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
  });

  timer.start({
    /* EasyTimer start configuration */
  });

  return (
    <div className="font-neo bg-white" id="home">
      <nav className="fixed top-0 w-full bg-black text-white py-4 z-10">
        <div className="max-w-6xl mx-auto px-6 flex items-center justify-between">
          <a href="/">
            <img src="/images/logo/logo-white.webp" alt="Logo" className="h-12" />
          </a>
          <div className="block md:hidden">
            <button onClick={() => setOpen(!open)}>
              <Icon icon="heroicons-outline:menu-alt-1" className="h-8 w-8" />
            </button>
          </div>
          <div className="hidden md:flex items-center space-x-4">
            <a
              href="#home"
              className="inline-block px-6 py-1.5 border border-white/70 hover:text-white/70 hover:border-kggreen hover:shadow-neon hover:animate-pulse transition"
            >
              Home
            </a>
            <a
              href="#team"
              className="inline-block px-6 py-1.5 border border-white/70 hover:text-white/70 hover:border-kggreen hover:shadow-neon hover:animate-pulse transition"
            >
              Team
            </a>
            <a
              href="#roadmap"
              className="inline-block px-6 py-1.5 border border-white/70 hover:text-white/70 hover:border-kggreen hover:shadow-neon hover:animate-pulse transition"
            >
              Roadmap
            </a>
            <a
              href="#"
              className="inline-block px-6 py-1.5 border border-white/70 hover:text-white/70 hover:border-kggreen hover:shadow-neon hover:animate-pulse transition"
            >
              MIU Holders
            </a>
            <a href="/dashboard" className="inline-block px-6 py-1.5 border border-kggreen bg-kggreen">
              Enter Miawhalla
            </a>
            <div className="flex space-x-2">
              <a href="https://twitter.com/khuganft" className="inline-block p-2">
                <Icon icon="akar-icons:twitter-fill" />
              </a>
              <a href="https://discord.gg/khuga" className="inline-block p-2">
                <Icon icon="akar-icons:discord-fill" />
              </a>
            </div>
          </div>
        </div>
        <div className={`bg-black px-6 pt-8 pb-4 ${open ? "flex flex-col" : "hidden"}`}>
          <div className="grid grid-cols-2 gap-4">
            <a
              href="#home"
              className="inline-block px-6 py-1.5 border border-white/70 hover:text-white/70 hover:border-kggreen hover:shadow-neon hover:animate-pulse transition"
            >
              Home
            </a>
            <a
              href="#team"
              className="inline-block px-6 py-1.5 border border-white/70 hover:text-white/70 hover:border-kggreen hover:shadow-neon hover:animate-pulse transition"
            >
              Team
            </a>
            <a
              href="#roadmap"
              className="inline-block px-6 py-1.5 border border-white/70 hover:text-white/70 hover:border-kggreen hover:shadow-neon hover:animate-pulse transition"
            >
              Roadmap
            </a>
            <a
              href="#roadmap"
              className="inline-block px-6 py-1.5 border border-white/70 hover:text-white/70 hover:border-kggreen hover:shadow-neon hover:animate-pulse transition"
            >
              MIU Holders
            </a>
            <a href="/dashboard" className="inline-block px-6 py-1.5 border border-kggreen bg-kggreen">
              Enter Miawhalla
            </a>
          </div>
          <div className="flex justify-center space-x-2 mt-4">
            <a href="https://twitter.com/khuganft" className="inline-block p-2">
              <Icon icon="akar-icons:twitter-fill" />
            </a>
            <a href="https://discord.gg/khuga" className="inline-block p-2">
              <Icon icon="akar-icons:discord-fill" />
            </a>
          </div>
        </div>
      </nav>

      <div className="relative w-full min-h-screen bg-cover bg-center flex justify-center items-end pb-8 top-20 bg-[#024134]">
        <div className="absolute inset-0">
          <img src="/images/bg/bg-header.webp" alt="Background" className="w-full" />
        </div>
        <div className="absolute inset-0 text-center space-y-8 mt-24">
          <h2 className="font-bold text-3xl lg:text-5xl text-white">ADOPT NOW</h2>
          <Icon icon="heroicons-outline:chevron-double-down" className="h-10 w-10 text-kgneon mx-auto animate-bounce" />
          <img src="/images/illustration/box-adopt.webp" alt="Adopt Me" className="w-48 sm:w-52 md:w-64 lg:w-72 mx-auto" />
          <div className="flex space-x-4 justify-center">
            <div className="w-16 md:w-24 h-24 md:h-32 bg-kgneon rounded-md flex items-center justify-center">
              <span id="day" className="font-bold text-2xl md:text-3xl">
                {timer.getTimeValues().toString(["days"])}
              </span>
            </div>
            <div className="w-16 md:w-24 h-24 md:h-32 bg-kgneon rounded-md flex items-center justify-center">
              <span id="hour" className="font-bold text-2xl md:text-3xl">
                {timer.getTimeValues().toString(["hours"])}
              </span>
            </div>
            <div className="w-16 md:w-24 h-24 md:h-32 bg-kgneon rounded-md flex items-center justify-center">
              <span id="min" className="font-bold text-2xl md:text-3xl">
                {timer.getTimeValues().toString(["minutes"])}
              </span>
            </div>
            <div className="w-16 md:w-24 h-24 md:h-32 bg-kgneon rounded-md flex items-center justify-center">
              <span id="sec" className="font-bold text-2xl md:text-3xl">
                {timer.getTimeValues().toString(["seconds"])}
              </span>
            </div>
          </div>
          <p className="uppercase text-xl text-white">
            From <strong>5555</strong> Khugas
          </p>
        </div>
      </div>

      <div className="w-full min-h-screen bg-cover bg-center flex items-center justify-center" style={{ backgroundImage: `url('/images/bg/bg-char.webp')` }}>
        <div className="max-w-xl mx-auto">
          <img src="/images/logo/logo-green.webp" alt="Logo" className="mx-auto h-48" />
          <p className="text-center text-xl text-white">
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Eveniet ullam blanditiis perspiciatis. Ipsam, quaerat accusantium libero velit asperiores
            cum dicta facilis molestiae nemo a enim dolores cupiditate eaque corrupti nobis?
          </p>
        </div>
      </div>

      <div className="max-w-5xl mx-auto px-4 py-20">
        <p className="text-center text-xl text-black mb-12">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse
          ultrices gravida.
        </p>
        <div className="grid grid-cols-6 gap-6">
          <div className="col-span-6 lg:col-span-3">
            <img src="/images/character/shiro.webp" alt="Shiro" className="w-full" />
          </div>
          <div className="col-span-6 lg:col-span-3">
            <img src="/images/character/kuro.webp" alt="Kuro" className="w-full" />
          </div>
          <div className="col-span-6 lg:col-span-2">
            <img src="/images/character/soon.webp" alt="Soon" className="w-full" />
          </div>
          <div className="col-span-6 lg:col-span-2">
            <img src="/images/character/soon.webp" alt="Soon" className="w-full" />
          </div>
          <div className="col-span-6 lg:col-span-2">
            <img src="/images/character/soon.webp" alt="Soon" className="w-full" />
          </div>
        </div>
      </div>

      {/* <div id="team" className="max-w-5xl mx-auto px-4 py-20">
        <h2 className="text-5xl font-bold text-center mb-8">Our Team</h2>
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-12">
          <div className="text-center">
            <div className="w-36 h-36 rounded-[20%] bg-kggreen mx-auto mb-2 flex justify-center items-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <h6 className="font-bold text-xl">Alip S.</h6>
            <p className="text-black/70">Lorem ipsum</p>
          </div>
          <div className="text-center">
            <div className="w-36 h-36 rounded-[20%] bg-kggreen mx-auto mb-2 flex justify-center items-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <h6 className="font-bold text-xl">B. Arya</h6>
            <p className="text-black/70">Lorem ipsum</p>
          </div>
          <div className="text-center">
            <div className="w-36 h-36 rounded-[20%] bg-kggreen mx-auto mb-2 flex justify-center items-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <h6 className="font-bold text-xl">Aji Kobayashi</h6>
            <p className="text-black/70">Lorem ipsum</p>
          </div>
          <div className="text-center">
            <div className="w-36 h-36 rounded-[20%] bg-kggreen mx-auto mb-2 flex justify-center items-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <h6 className="font-bold text-xl">Galang</h6>
            <p className="text-black/70">Lorem ipsum</p>
          </div>
          <div className="text-center">
            <div className="w-36 h-36 rounded-[20%] bg-kggreen mx-auto mb-2 flex justify-center items-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <h6 className="font-bold text-xl">Jim</h6>
            <p className="text-black/70">Lorem ipsum</p>
          </div>
          <div className="text-center">
            <div className="w-36 h-36 rounded-[20%] bg-kggreen mx-auto mb-2 flex justify-center items-center">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>
            <h6 className="font-bold text-xl">Ahmad</h6>
            <p className="text-black/70">Lorem ipsum</p>
          </div>
        </div>
      </div> */}
      <div id="roadmap">
        <img src="/images/bg/bg-roadmap.webp" alt="Roadmap" className="rounded-t-[15%] border-t-[1rem] border-kgneon" />
      </div>
    </div>
  );
}

export default LandingPage;
