import { Icon } from "@iconify/react";
import { useEffect } from "react";
import LayoutBase from "../layouts/LayoutBase";
import CardCollection from "../partials/card/CardCollection";
import useNft from "../web3/Nft";

let getOwnedNfts = [];

function CollectionsDetail() {
  const { getOwnedNFTs } = useNft();

  useEffect(() => {
    getOwnedNFTs().then((r) => (getOwnedNfts = r));
  }, []);

  return (
    <LayoutBase>
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <h1 className="font-bold text-2xl mb-4 font-bungee">NFT Detail</h1>
        <div className="grid grid-cols-1 md:grid-cols-2">
          <div>
            <img src="https://via.placeholder.com/512?text=Placeholder" alt="Placeholder" className="w-full" />
          </div>
          <div className="bg-white p-8">
            <h2 className="font-bold text-3xl mb-2">Khuga NFT #1</h2>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum, unde totam? Optio iste laudantium distinctio voluptates? Vitae eveniet veniam odio.</p>
            <hr className="my-4" />
            <ul className="text-lg space-y-4">
              <li className="flex items-center space-x-2">
                <Icon icon="bx:fingerprint" className="w-7 h-7" />
                <span>
                  <strong>Rarity:</strong> Legendary
                </span>
              </li>
              {/* <li className="flex items-center space-x-2">
                <Icon icon="bx:crosshair" className="w-7 h-7" />
                <span>
                  <strong>Damage:</strong> 600pt
                </span>
              </li> */}
              {/* <li className="flex items-center space-x-2">
                <Icon icon="bx:shield-quarter" className="w-7 h-7" />
                <span>
                  <strong>Armor:</strong> Chainmail
                </span>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </LayoutBase>
  );
}

export default CollectionsDetail;
