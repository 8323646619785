import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { CartProvider } from "react-use-cart";
import App from "./App";
import { MoralisProvider } from "react-moralis";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <MoralisProvider serverUrl={process.env.REACT_APP_MORALIS_SERVER} appId={process.env.REACT_APP_MORALIS_APP_ID}>
        <CartProvider>
          <App />
        </CartProvider>
      </MoralisProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
