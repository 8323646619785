import { useEffect } from "react";
import LayoutBase from "../layouts/LayoutBase";
import CardCollection from "../partials/card/CardCollection";
import useNft from "../web3/Nft";

let getOwnedNfts = [];

function Collections() {
  const { getOwnedNFTs } = useNft();

  useEffect(() => {
    getOwnedNFTs().then((r) => (getOwnedNfts = r));
  }, []);

  return (
    <LayoutBase>
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <h1 className="font-bold text-2xl mb-4 font-bungee">My Collections</h1>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mb-8">
          {getOwnedNfts && getOwnedNfts.length > 0 ? (
            getOwnedNfts.map((item, index) => <CardCollection key={index} image={item.metadata.image} title={item.metadata.name} />)
          ) : (
            <div>You don't have a collection yet</div>
          )}
        </div>
      </div>
    </LayoutBase>
  );
}

export default Collections;
