import { createContext, useState } from "react";
import { useCart } from "react-use-cart";
import LayoutBase from "../layouts/LayoutBase";
import Payment from "../partials/checkout/Payment";
import PersonalInformation from "../partials/checkout/PersonalInformation";
import ShippingAddress from "../partials/checkout/ShippingAddress";
import Step from "../partials/checkout/Step";
import Stepper from "../partials/checkout/Stepper";

export const CheckoutContext = createContext();

function Checkout() {
  const { items, cartTotal } = useCart();
  const [activeStepIndex, setActiveStepIndex] = useState(0);
  const [formData, setFormData] = useState({});

  return (
    <LayoutBase>
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <h1 className="font-bold text-2xl mb-4 font-bungee">Checkout</h1>
        <div className="grid grid-cols-12 gap-6">
          <CheckoutContext.Provider value={{ activeStepIndex, setActiveStepIndex, formData, setFormData }}>
            <div className="col-span-8 bg-white p-4 md:p-6 rounded shadow">
              <Stepper items={["Personal Information", "Shipping Address", "Payment"]} />
              <hr className="my-6" />
              <Step items={[<PersonalInformation />, <ShippingAddress />, <Payment />]} />
            </div>
          </CheckoutContext.Provider>

          <div className="col-span-4">
            <div className="bg-white rounded shadow-lg divide-y divide-gray-200">
              <div className="p-4">
                <h2 className="font-bold text-xl font-bungee">Order Summary</h2>
              </div>
              <div className="p-4 text-gray-500">
                {items.map((item, index) => (
                  <div key={index} className="flex justify-between items-center">
                    <span>
                      {item.quantity}x {item.name}
                    </span>
                    <span>${item.quantity * item.price}</span>
                  </div>
                ))}
              </div>
              <div className="p-4 text-gray-500">
                <div className="flex justify-between items-center">
                  <span>Subtotal</span>
                  <span>${cartTotal}</span>
                </div>
                <div className="flex justify-between items-center">
                  <span>Shipping</span>
                  <span>-</span>
                </div>
                <hr className="my-4" />
                <div className="flex justify-between items-center text-gray-600">
                  <span>Total</span>
                  <h6 className="font-bold text-xl">${cartTotal}</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LayoutBase>
  );
}

export default Checkout;
