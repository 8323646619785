import axios from "axios";
import axiosRetry, { isNetworkOrIdempotentRequestError }  from "axios-retry";

const covalentAbortController = new AbortController();

const covalentAPIClient = axios.create({
    baseURL: "https://api.covalenthq.com/",
    headers: {
        Accept: "application/json",
    },
    auth: {
        username: process.env.REACT_APP_COVALENT_API_CKEY 
    },
    signal: covalentAbortController.signal 
});

axiosRetry(covalentAPIClient, {
    retries: 3,
    retryDelay: axiosRetry.exponentialDelay,
    retryCondition: (c) => (isNetworkOrIdempotentRequestError(c) || c.response.status === 429)
});

export {
    covalentAbortController,
    covalentAPIClient,
};