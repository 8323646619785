import LayoutBase from "../layouts/LayoutBase";
import Chips from "../partials/actions/Chip";
import toast, { Toaster } from "react-hot-toast";
import CardDigitalGoodies from "../partials/card/CardDigitalGoodies";

const products = [
  {
    id: "D1",
    name: "Digital Goodies #1",
    image: "https://i.postimg.cc/3wf3W4d9/nft1.jpg",
  },
  {
    id: "D2",
    name: "Digital Goodies #2",
    image: "https://i.postimg.cc/BvqxMnG9/fcf82011-e249-439f-b2e4-902c0c2a6a69.jpg",
  },
  {
    id: "D2",
    name: "Digital Goodies #3",
    image: "https://i.postimg.cc/PqwGDT7G/photo1655197299.jpg",
  },
];

function DigitalGoodies() {
  return (
    <LayoutBase>
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <h1 className="font-bold text-2xl mb-4 font-bungee">Digital Goodies</h1>

        {/* Filter */}
        {/* <div className="space-x-2 space-y-2 mb-4">
          <Chips title="View All" isActive={true} />
          <Chips title="Desktop" isActive={false} />
          <Chips title="Smartphone" isActive={false} />
          <Chips title="Smartwatch" isActive={false} />
        </div> */}

        {/* Items */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {products.map((p) => (
            <CardDigitalGoodies key={p.id} image={p.image} title={p.name} />
          ))}
        </div>
      </div>

      <Toaster />
    </LayoutBase>
  );
}

export default DigitalGoodies;
