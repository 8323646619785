import React, { useContext } from "react";
import { CheckoutContext } from "../../pages/Checkout";

function Payment() {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } = useContext(CheckoutContext);

  return (
    <>
      <h2>Payment</h2>
      <div className="space-x-2 mt-10">
        <button
          className="px-6 py-2 bg-gray-300 text-gray-600 rounded hover:opacity-90 font-bungee text-sm"
          type="button"
          onClick={() => setActiveStepIndex(activeStepIndex - 1)}
        >
          Prev.
        </button>
      </div>
    </>
  );
}

export default Payment;
