import LayoutBase from "../layouts/LayoutBase";
import toast, { Toaster } from "react-hot-toast";
import UserAvatar from "../images/user-avatar-32.png";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as yup from "yup";

function Profile() {
  const ValidationSchema = yup.object().shape({
    wallet: yup.string().required(),
    username: yup.string().required(),
    email: yup.string().email().required(),
    discord: yup.string().required(),
    twitter: yup.string().required(),
  });

  const renderError = (message) => <p className="text-sm italic text-red-600">{message}</p>;

  return (
    <LayoutBase>
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <h1 className="font-bold text-2xl mb-4 font-bungee">Profile</h1>
        <Formik
          initialValues={{
            wallet: "0xb223982251449ef8b34403bd363cd581c42b6e0b",
            username: "",
            email: "",
            discord: "",
            twitter: "",
          }}
          validationSchema={ValidationSchema}
          onSubmit={(values) => {
            console.log(values);
            toast.success("TODO: Integrate with backend", { position: "top-right" });
          }}
        >
          <Form className="max-w-2xl bg-white p-4 md:p-6 rounded shadow space-y-4">
            <img src={UserAvatar} alt="Profile" className="rounded-full w-24 border-4 border-primary-700" />
            <div className="space-y-2">
              <label htmlFor="wallet">Wallet Address</label>
              <input
                id="wallet"
                type="text"
                className="block w-full border-gray-300 rounded bg-gray-100"
                placeholder="...."
                required
                readOnly
                value="0xb223982251449ef8b34403bd363cd581c42b6e0b"
              />
            </div>
            <div className="space-y-1">
              <label htmlFor="username">Username</label>
              <Field name="username" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
              <ErrorMessage name="username" render={renderError} />
            </div>
            <div className="space-y-1">
              <label htmlFor="email">Email</label>
              <Field name="email" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
              <ErrorMessage name="email" render={renderError} />
            </div>
            <div className="space-y-1">
              <label htmlFor="discord">Discord ID</label>
              <Field name="discord" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
              <ErrorMessage name="discord" render={renderError} />
            </div>
            <div className="space-y-1">
              <label htmlFor="twitter">Twitter</label>
              <Field name="twitter" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
              <ErrorMessage name="twitter" render={renderError} />
            </div>
            <hr />
            <button type="submit" className="px-6 py-2 bg-primary-800 text-white rounded hover:opacity-90">
              Update Profile
            </button>
          </Form>
        </Formik>
      </div>

      <Toaster />
    </LayoutBase>
  );
}

export default Profile;
