import LayoutBase from "../layouts/LayoutBase";
import toast, { Toaster } from "react-hot-toast";
import { useRef } from "react";
import useNft from "../web3/Nft";

function VoucherGenerator() {
	const { generateVoucher } = useNft();

  const quantityToSign = useRef("");
  const walletAddress = useRef("");
  const email = useRef("");

	const generatorHandler = (event) => {
    event.preventDefault();

    generateVoucher(walletAddress.current.value, quantityToSign.current.value)
			.then((res) => {
				console.log(res);
    		toast.success("Success generate voucher", { position: "top-right" });
			})
			.catch((e) => {
				console.log(e);
				toast.error("Failed generate voucher", { position: "top-right" });
			});
  };

  return (
    <LayoutBase>
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <h1 className="font-bold text-2xl mb-4 font-bungee">Voucher Generator</h1>
        <div className="max-w-2xl bg-white p-4 md:p-6 rounded shadow">
          <form onSubmit={generatorHandler} className="space-y-4">
            <div className="space-y-2">
              <label htmlFor="quantityToSign">Quantity To Sign</label>
              <input ref={quantityToSign} required id="quantityToSign" type="number" className="block w-full border-gray-300 rounded" placeholder="...." />
            </div>
            <div className="space-y-2">
              <label htmlFor="walletAddress">Wallet Address</label>
              <input ref={walletAddress} required id="walletAddress" type="text" className="block w-full border-gray-300 rounded" placeholder="...." />
            </div>
            <div className="space-y-2">
              <label htmlFor="email">Email</label>
              <input ref={email} required id="email" type="email" className="block w-full border-gray-300 rounded" placeholder="...." />
            </div>
            <button type="submit" className="px-6 py-2 bg-primary-800 text-white rounded hover:opacity-90">
              Process
            </button>
          </form>
        </div>
      </div>

      <Toaster />
    </LayoutBase>
  );
}

export default VoucherGenerator;
