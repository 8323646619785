import LayoutBase from "../layouts/LayoutBase";
import Chips from "../partials/actions/Chip";
import CardMerchandise from "../partials/card/CardMerchandise";
import { useCart } from "react-use-cart";
import toast, { Toaster } from "react-hot-toast";

const products = [
  {
    id: 1,
    name: "Cast Statue #1",
    image: "https://via.placeholder.com/640x640",
    price: 50,
    quantity: 1,
  },
  {
    id: 2,
    name: "Cast Statue #2",
    image: "https://via.placeholder.com/640x640",
    price: 99,
    quantity: 1,
  },
  {
    id: 3,
    name: "Cast Statue #3",
    image: "https://via.placeholder.com/640x640",
    price: 120,
    quantity: 1,
  },
  {
    id: 4,
    name: "Cast Statue #4",
    image: "https://via.placeholder.com/640x640",
    price: 15,
    quantity: 1,
  },
];

function Merchandise() {
  const { addItem } = useCart();

  const addItemHandler = (product) => {
    addItem(product);
    toast.success("Added to cart.", {position: 'top-right'});
  };

  return (
    <LayoutBase>
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <h1 className="font-bold text-2xl mb-4 font-bungee">Merchandise</h1>

        {/* Filter */}
        {/* <div className="space-x-2 space-y-2 mb-4">
          <Chips title="View All" isActive={true} />
          <Chips title="Hat" isActive={false} />
          <Chips title="T-Shirt" isActive={false} />
          <Chips title="Tumblr" isActive={false} />
          <Chips title="Jacket" isActive={false} />
          <Chips title="e-Money" isActive={false} />
        </div> */}

        {/* Items */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {products.map((p) => (
            <CardMerchandise key={p.id} image={p.image} title={p.name} price={p.price} onAddItem={() => addItemHandler(p)} />
          ))}
        </div>
      </div>

      <Toaster />
    </LayoutBase>
  );
}

export default Merchandise;
