import { ErrorMessage, Field, Form, Formik } from "formik";
import React, { useContext } from "react";
import { CheckoutContext } from "../../pages/Checkout";
import * as yup from "yup";

function ShippingAddress() {
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } = useContext(CheckoutContext);

  const renderError = (message) => <p className="text-sm italic text-red-600">{message}</p>;

  const ValidationSchema = yup.object().shape({
    address: yup.string().required(),
    city: yup.string().required(),
    country: yup.string().required(),
    province: yup.string().required(),
    postal_code: yup.string().required(),
  });

  return (
    <Formik
      initialValues={{
        address: formData.address || "",
        city: formData.city || "",
        country: formData.country || "",
        province: formData.province || "",
        postal_code: formData.postal_code || "",
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values) => {
        const data = { ...formData, ...values };
        setFormData(data);
        console.log(data);
        setActiveStepIndex(activeStepIndex + 1);
      }}
    >
      <Form className={`space-y-4`}>
        <div className="space-y-2">
          <label htmlFor="address">Address</label>
          <Field name="address" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." as="textarea" />
          <ErrorMessage name="address" render={renderError} />
        </div>
        <div className="space-y-2">
          <label htmlFor="city">City</label>
          <Field name="city" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
          <ErrorMessage name="city" render={renderError} />
        </div>
        <div className="space-y-2">
          <label htmlFor="country">Country</label>
          <Field name="country" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
          <ErrorMessage name="country" render={renderError} />
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="space-y-2">
            <label htmlFor="province">State/Province</label>
            <Field name="province" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
            <ErrorMessage name="province" render={renderError} />
          </div>
          <div className="space-y-2">
            <label htmlFor="postal_code">Postal Code</label>
            <Field name="postal_code" className="block w-full px-4 py-2 border border-gray-300 rounded" placeholder="...." />
            <ErrorMessage name="postal_code" render={renderError} />
          </div>
        </div>

        <div className="space-x-2">
          <button
            className="px-6 py-2 bg-gray-300 text-gray-600 rounded hover:opacity-90 font-bungee text-sm"
            type="button"
            onClick={() => setActiveStepIndex(activeStepIndex - 1)}
          >
            Prev.
          </button>
          <button className="px-6 py-2 bg-primary-800 text-white rounded hover:opacity-90 font-bungee text-sm" type="submit">
            Next
          </button>
        </div>
      </Form>
    </Formik>
  );
}

export default ShippingAddress;
