function CardMerchandise({ image, title, price, onAddItem }) {
  return (
    <div className="bg-white rounded shadow hover:shadow-lg transition-all">
      <div className="relative">
        <img src={image} alt="Item" className="w-full aspect-[5/4] object-cover rounded-t" />
        <span className="inline-block absolute bottom-4 right-4 rounded-md text-white text-sm px-2 py-1 bg-gray-700">${price}</span>
      </div>
      <div className="p-4">
        <h6 className="font-bold">{title}</h6>
        <p className="text-xs line-clamp-2 mb-4">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus laudantium perspiciatis incidunt temporibus
        </p>
        <button onClick={() => onAddItem()} className="w-full p-2 tracking-wider text-white bg-primary-800 rounded text-xs font-bold border border-primary-900 hover:bg-opacity-90 active:scale-95 active:bg-opacity-80 transition uppercase font-bungee">
          Add To Cart
        </button>
      </div>
    </div>
  );
}

export default CardMerchandise;
