import LayoutBase from "../layouts/LayoutBase";
import { Icon } from "@iconify/react";
import useTimer from "easytimer-react-hook";

import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/dist/css/splide.min.css";
import CardCollection from "../partials/card/CardCollection";
import useNft from "../web3/Nft";
import { useEffect } from "react";
import { useMoralis } from "react-moralis";
import { NavLink } from "react-router-dom";

let topOwners = [];
let latestNfts = [];
let mintedTotalNfts = [];

function Home() {
  const { isInitialized } = useMoralis();
  const { topOwnersByAmount, latestNFTs, mintedAndTotalNFTs } = useNft();

  useEffect(() => {
    topOwnersByAmount().then((r) => (topOwners = r));
    if (isInitialized) {
      latestNFTs().then((r) => (latestNfts = r));
      mintedAndTotalNFTs().then((r) => (mintedTotalNfts = r));
    }
  }, [isInitialized]);

  const [timer, isTargetAchieved] = useTimer({
    countdown: true,
    startValues: {
      days: 2,
      hours: 5,
      minutes: 1,
      seconds: 10,
    },
    target: {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    },
  });

  timer.start({
    /* EasyTimer start configuration */
  });

  return (
    <LayoutBase>
      {/* Latest News */}
      <div className="bg-gradient-to-r from-primary-100 to-primary-50 w-full border-b-2 border-primary-600 shadow-md">
        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto space-y-2">
          <div className="flex items-center space-x-2 text-primary-600">
            <Icon icon="heroicons-outline:newspaper" className="w-5 h-5" />
            <span className="text-xs uppercase tracking-wider">Latest News</span>
          </div>
          <div className="flex flex-col md:flex-row justify-between">
            <div>
              <h2 className="text-2xl font-medium font-bungee">Private Sales Started</h2>
              <p className="text-sm">
                Lorem ipsum dolor, sit amet consectetur adipisicing elit. Suscipit asperiores iure a eveniet aspernatur qui eligendi debitis
              </p>
            </div>
            <div className="mt-4 md:mt-0">
              <a href="/" className="inline-flex px-4 py-2 bg-primary-800 text-white rounded font-bungee">
                Read More
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        {/* Phase */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
          <div className="w-full bg-white rounded shadow p-4">
            <div className="flex items-center space-x-2 text-primary-800 pb-2 mb-4 border-b border-primary-100">
              <Icon icon="heroicons-outline:cursor-click" className="w-5 h-5" />
              <span className="text-xs uppercase tracking-wider font-bungee">Current Phase</span>
            </div>
            <div className="flex justify-center items-center h-28">
              <div className="text-center p-4 w-full">
                <p className="mb-4">
                  <strong>
                    {mintedTotalNfts.minted ?? "-"}/{mintedTotalNfts.total ?? "-"}
                  </strong>{" "}
                  items minted
                </p>
                <div className="relative w-full h-4 bg-primary-50 rounded-md">
                  {mintedTotalNfts.minted ? (
                    <div
                      className="absolute h-4 bg-primary-800 rounded-md animate-pulse"
                      style={{ width: `${(mintedTotalNfts.minted / mintedTotalNfts.total)*100}%` }}
                    ></div>
                  ) : (
                    <div className="absolute h-4 bg-primary-800 rounded-md animate-pulse" style={{ width: "0%" }}></div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="w-full bg-white rounded shadow p-4">
            <div className="flex items-center space-x-2 text-primary-800 pb-2 mb-4 border-b border-primary-100">
              <Icon icon="heroicons-outline:trending-up" className="w-5 h-5" />
              <span className="text-xs uppercase tracking-wider font-bungee">Next Phase</span>
            </div>
            <div className="flex justify-center items-center h-28">
              <div className="text-center p-4">
                <p>Next Phase Started At</p>
                <h2 className="font-bold text-4xl">{timer.getTimeValues().toString(["days", "hours", "minutes", "seconds"])}</h2>
              </div>
            </div>
          </div>
        </div>

        {/* Latest Minted NFTs */}
        <div>
          <h2 className="font-bold text-xl mb-4 font-bungee">Latest Minted NFTs</h2>
          <Splide
            options={{
              rewind: true,
              perPage: 5,
              gap: "1rem",
              breakpoints: {
                480: {
                  perPage: 1,
                },
                768: {
                  perPage: 3,
                },
                976: {
                  perPage: 4,
                },
              },
            }}
          >
            {latestNfts.map((item, index) => (
              <SplideSlide key={index} className="pb-8">
                <NavLink to="/collections/1">
                  <CardCollection image={item.metadata.image} title={item.metadata.name} />
                </NavLink>
              </SplideSlide>
            ))}
          </Splide>
        </div>

        {/* Top 20 NFT Owners */}
        <div>
          <h2 className="font-bold text-xl mb-4 font-bungee">Top 20 NFT Owners</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
            <div className="w-full bg-white rounded shadow overflow-auto">
              <table className="table-auto border-collapse border border-gray-200 w-full">
                <thead>
                  <tr className="bg-gray-50">
                    <th className="px-4 py-3 text-right">Amount</th>
                    <th className="px-4 py-3 text-left">Address</th>
                  </tr>
                </thead>
                <tbody>
                  {topOwners.map((item, index) => (
                    <tr key={index}>
                      <td className="px-4 py-1.5 border border-gray-100 text-right">
                        <strong>{item.amount}</strong>
                      </td>
                      <td className="px-4 py-1.5 border border-gray-100">{item.addres}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </LayoutBase>
  );
}

export default Home;
