import { abiJson } from "./abi.json"
import { useState } from "react";
import { useMoralis, useMoralisWeb3Api } from "react-moralis";
import { covalentAbortController, covalentAPIClient } from "../utils/authenticated-api-call";

/**
 * Generate whitelist mint hash
 * @param {string} walletAddress 
 * @param {integer} qtyToSign 
 * @returns {string} - eip712-whitelist-voucher
 */
const generateTypedData = (walletAddress, qtyToSign) => {
	return {
		types: {
			EIP712Domain: [
				{ name: "name", type: "string" },
				{ name: "version", type: "string" },
				{ name: "chainId", type: "uint256" },
				{ name: "verifyingContract", type: "address" },
			],
			Whitelist_Type: {
				Whitelist: [
					{ name: "buyer", type: "address" },
					{ name: "signedQty", type: "uint256" },
					{ name: "nonce", type: "uint256" },
				]
			}
		},
		primaryType: 'Whitelist',
		domain: {
			name: 'MNFT',
			version: '4',
			chainId: 4,
			verifyingContract: process.env.REACT_APP_EIP712_VERIFYING_CONTRACT
		},
		value: {
			buyer: walletAddress,
			signedQty: qtyToSign,
			nonce: window.performance.now() + window.performance.timeOrigin
		}
	}
};

/**
 * Execute NFT eip712 mint
 * @param {MoralisContext} moralis 
 * @param {boolean} isAuthenticated 
 * @param {object} mintParams - object of mintQty, signedQty, signature and nonce
 * @returns 
 */
const PresaleMint = async (moralis, isAuthenticated, mintParams) => {
	if (!isAuthenticated) throw "Not Authenticated";

	const mintOptions = {
		contractAddress: process.env.REACT_APP_MEONG_WARRIOR_NFT_CONTRACT_ADDRESS.toString(),
		functionName: "whitelistSalesMint",
		abi: abiJson,
		params: {
			_mintQty: 1,
			_signedQty: mintParams.signedQty,
			_nonce: mintParams.nonce,
			_signature: mintParams.signature,
		},
	}

	const transaction = await moralis.executeFunction(mintOptions);
	return transaction;
};

/**
 * Generate whitelist voucher
 * @param {MoralisInstance} moralis 
 * @param {string} buyer 
 * @param {integer} qty 
 * @returns 
 */
const web3SignTypedData = async (moralis, buyer, qty) => {
	const provider = await moralis.enableWeb3();
	const userAddress = moralis.account;
	const signer = provider.getSigner(userAddress);
	const whitelistTypedData = generateTypedData(buyer, qty);
	const signature = await signer._signTypedData(whitelistTypedData.domain, whitelistTypedData.types.Whitelist_Type, whitelistTypedData.value);

	return signature;
}

/**
 * Get latest minted NFTs
 * @param {MoralisInstance} moralis 
 * @param {Web3Api} web3api 
 * @returns {array} - array of nft url and metadata
 */
const latestMintedNFTs = async (moralis, web3api) => {
	let latestNFTs = await web3api.token.getNFTOwners({
		chain: "rinkeby",
		address: process.env.REACT_APP_MEONG_WARRIOR_NFT_CONTRACT_ADDRESS,
		limit: 10,
	});

	latestNFTs = latestNFTs?.result?.map((x) => {
		return {
			market_url: `https://testnets.opensea.io/assets/${process.env.REACT_APP_MEONG_WARRIOR_NFT_CONTRACT_ADDRESS}/${x.token_id}`,
			metadata: JSON.parse(x?.metadata)
		}
	});

	return latestNFTs;
}

/**
 * Get top NFTs owners
 * @param {integer} limit 
 * @returns {array} - array of top owners
 */
const topOwnersByAmount = async (limit = 20) => {
	let topOwners = await covalentAPIClient
		.get(`/v1/1/tokens/${process.env.REACT_APP_KARAFURU_NFT_CONTRACT_ADDRESS}/token_holders/?quote-currency=USD&format=JSON&limit=20&key=${process.env.REACT_APP_COVALENT_API_CKEY}`)
		.catch((e) => {
			console.log(e);
			if (e.response?.status !== 200) {
				throw new Error(`Error to fetch owners, Error ${e.response.status}`);
			}
		});

	topOwners = topOwners?.data?.data?.items?.map((x) => { return { addres: x?.address, amount: x?.balance } });

	return topOwners;
};

/**
 * Get NFTs owned by specific user address
 * @param {MoralisInstance} moralis 
 * @param {boolean} isAuthenticated 
 * @param {Web3ApiInstance} web3api 
 * @param {string} account - user wallet address
 * @returns 
 */
const ownedNFTs = async (moralis, isAuthenticated, web3api, account = null) => {
	if (!isAuthenticated) {
		throw "Not Authenticated";
	}
	
	const options = {
		chain: "rinkeby",
		address: (isAuthenticated && account) ? account : moralis.account,
		token_address: process.env.REACT_APP_MEONG_WARRIOR_NFT_CONTRACT_ADDRESS,
	};

	const nfts = await web3api.account.getNFTsForContract(options).catch((e) => {
		console.log(e);
		throw new Error("Failed to get NFTs");
	});

	return nfts?.result?.map((x) => {
		return {
			market_url: `https://testnets.opensea.io/assets/${process.env.REACT_APP_MEONG_WARRIOR_NFT_CONTRACT_ADDRESS}/${x.token_id}`,
			metadata: JSON.parse(x?.metadata)
		}
	});
}

/**
 * Fetch NFT Collection
 * @param {MoralisInstance} moralis 
 * @param {Web3ApiInstance} web3api 
 * @param {ReactState} nftFetchState 
 * @returns 
 */
const fetchNFTCollection = async (web3api, nftFetchState) => {
	const [NFTFetchState, setNFTFetchState] = nftFetchState;

	if (NFTFetchState.cursor === "") return [];

	const NFTCollection = await web3api.token.getNFTOwners({
		chain: "rinkeby",
		address: process.env.REACT_APP_MEONG_WARRIOR_NFT_CONTRACT_ADDRESS,
		limit: 20,
		cursor: NFTFetchState.cursor
	})
	.catch((e) => {
		console.log(e);
		throw new Error("Failed to fetch collection");
	});

	setNFTFetchState({...NFTFetchState, cursor: NFTCollection.cursor});

	return NFTCollection?.result?.map((x) => {
		return {
			market_url: `https://testnets.opensea.io/assets/${process.env.REACT_APP_MEONG_WARRIOR_NFT_CONTRACT_ADDRESS}/${x.token_id}`,
			metadata: JSON.parse(x?.metadata)
		}
	});
}

const _MintedAndTotalNFTs = async (web3api) => {
	const nfts = await web3api.token.getNFTOwners({
		chain: "rinkeby",
		address: process.env.REACT_APP_MEONG_WARRIOR_NFT_CONTRACT_ADDRESS,
		limit: 1,
	})
	.catch((e) => {
		console.log(e);
		throw new Error("Failed to fetch nft");
	});

	return { minted: nfts?.total, total: process.env.REACT_APP_NFT_MAX_MINT_COUNT };
}

export const useNft = () => {
	const { Moralis, isAuthenticated, user } = useMoralis();
	const web3api = useMoralisWeb3Api();
	const NFTFetchState = useState({cursor: null});
	return {
		Mint: (mintParams) => PresaleMint(Moralis, isAuthenticated, mintParams),
		getOwnedNFTs: (account) => ownedNFTs(Moralis, isAuthenticated, web3api, account),
		latestNFTs: () => latestMintedNFTs(Moralis, web3api),
		generateVoucher: (buyer, qty) => web3SignTypedData(Moralis, buyer, qty),
		getCollection: () => fetchNFTCollection(web3api, NFTFetchState),
		mintedAndTotalNFTs: () => _MintedAndTotalNFTs(web3api),
		topOwnersByAmount
	}
};

export default useNft;