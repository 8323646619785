import LayoutBase from "../layouts/LayoutBase";
import toast, { Toaster } from "react-hot-toast";
import { useRef } from "react";
import useNft from "../web3/Nft";

function SelfGeneratedTicket() {
	const { generateVoucher } = useNft();

  const ticketType = useRef("");
  const walletAddress = useRef("0xb223982251449ef8b34403bd363cd581c42b6e0b");
  const email = useRef("");
  const discord = useRef("");

	const generatorHandler = (event) => {
    event.preventDefault();

    toast.success("TODO: Integrate with backend", { position: "top-right" });
  };

  return (
    <LayoutBase>
      <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
        <h1 className="font-bold text-2xl mb-4 font-bungee">Self Generated Ticket</h1>
        <div className="max-w-2xl bg-white p-4 md:p-6 rounded shadow">
          <form onSubmit={generatorHandler} className="space-y-4">
            <div className="space-y-2">
              <label htmlFor="ticketType">Type</label>
              <select ref={ticketType} required id="ticketType" className="block w-full border-gray-300 rounded">
                <option value="Event">Event</option>
                <option value="Merchandise">Merchandise</option>
                <option value="Special Reward">Special Reward</option>
              </select>
            </div>
            <div className="space-y-2">
              <label htmlFor="walletAddress">Wallet Address</label>
              <input
                ref={walletAddress}
                required
                id="walletAddress"
                type="text"
                className="block w-full border-gray-300 rounded bg-gray-100"
                readOnly
                value="0xb223982251449ef8b34403bd363cd581c42b6e0b"
              />
            </div>
            <div className="space-y-2">
              <label htmlFor="email">Email</label>
              <input ref={email} required id="email" type="email" className="block w-full border-gray-300 rounded" placeholder="...." />
            </div>
            <div className="space-y-2">
              <label htmlFor="discord">Discord ID</label>
              <input ref={discord} required id="discord" type="text" className="block w-full border-gray-300 rounded" placeholder="...." />
            </div>
            <button type="submit" className="px-6 py-2 bg-primary-800 text-white rounded hover:opacity-90 font-bungee text-sm">
              Process
            </button>
          </form>
        </div>
      </div>

      <Toaster />
    </LayoutBase>
  );
}

export default SelfGeneratedTicket;
