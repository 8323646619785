import { Menu, Transition } from "@headlessui/react";
import { Icon } from "@iconify/react";
import { Fragment } from "react";
import { saveAs } from "file-saver";
import toast, { Toaster } from "react-hot-toast";

function CardDigitalGoodies({ image, title }) {
  const handleDownload = (type) => {
    setTimeout(() => {
      saveAs(`https://resimg.ahmadnf.xyz?type=${type}&src=${image}&bg=true`, `${title}-wallpaper.jpg`);
    }, 300);
    toast.success("Downloading...", { position: "top-right" });
  };

  return (
    <>
      <div className="bg-white rounded shadow hover:shadow-lg transition-all">
        <div className="relative">
          <img src={image} alt="Item" className="w-full aspect-[5/4] object-cover rounded-t" />
        </div>
        <div className="p-4">
          <h6 className="font-bold">{title}</h6>
          <p className="text-xs line-clamp-2 mb-4">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Delectus laudantium perspiciatis incidunt temporibus
          </p>
          <Menu as="div" className="relative block text-left">
            <div>
              <Menu.Button className="w-full flex justify-center items-center p-2 tracking-wider text-white bg-primary-800 rounded text-xs font-bold border border-primary-900 hover:bg-opacity-90 active:scale-95 active:bg-opacity-80 transition uppercase font-bungee">
                <span>Download</span>
                <Icon icon="heroicons-outline:chevron-down" className="ml-2 -mr-1 h-5 w-5 text-violet-200 hover:text-violet-100" />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="px-1 py-1 ">
                  <Menu.Item>
                    {({ active }) => (
                      <Menu as="div" className="relative block text-left">
                        <div>
                          <Menu.Button
                            className={`${
                              active ? "bg-primary-50 text-gray-900" : "text-gray-900"
                            } group flex w-full items-center justify-between rounded-md px-2 py-2 text-sm`}
                          >
                            <span>Desktop</span>
                            <Icon icon="heroicons-outline:chevron-right" />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute left-52 -mt-10 w-56 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="px-1 py-1 ">
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    onClick={() => handleDownload("mac")}
                                    className={`${
                                      active ? "bg-primary-50 text-gray-900" : "text-gray-900"
                                    } group flex w-full items-center justify-between rounded-md px-2 py-2 text-sm`}
                                  >
                                    <span>Mac (16:10)</span>
                                    <Icon icon="heroicons-outline:download" />
                                  </button>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    onClick={() => handleDownload("desktop")}
                                    className={`${
                                      active ? "bg-primary-50 text-gray-900" : "text-gray-900"
                                    } group flex w-full items-center justify-between rounded-md px-2 py-2 text-sm`}
                                  >
                                    <span>Others (16:9)</span>
                                    <Icon icon="heroicons-outline:download" />
                                  </button>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => handleDownload("phone")}
                        className={`${
                          active ? "bg-primary-50 text-gray-900" : "text-gray-900"
                        } group flex w-full items-center justify-between rounded-md px-2 py-2 text-sm`}
                      >
                        <span>Smartphone</span>
                        <Icon icon="heroicons-outline:download" />
                      </button>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <Menu as="div" className="relative block text-left">
                        <div>
                          <Menu.Button
                            className={`${
                              active ? "bg-primary-50 text-gray-900" : "text-gray-900"
                            } group flex w-full items-center justify-between rounded-md px-2 py-2 text-sm`}
                          >
                            <span>Smartwatch</span>
                            <Icon icon="heroicons-outline:chevron-right" />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute left-52 -mt-10 w-56 origin-top-left divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="px-1 py-1 ">
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    onClick={() => handleDownload("watch-a5")}
                                    className={`${
                                      active ? "bg-primary-50 text-gray-900" : "text-gray-900"
                                    } group flex w-full items-center justify-between rounded-md px-2 py-2 text-sm`}
                                  >
                                    <span>iWatch 5</span>
                                    <Icon icon="heroicons-outline:download" />
                                  </button>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    onClick={() => handleDownload("watch-a6")}
                                    className={`${
                                      active ? "bg-primary-50 text-gray-900" : "text-gray-900"
                                    } group flex w-full items-center justify-between rounded-md px-2 py-2 text-sm`}
                                  >
                                    <span>iWatch 6</span>
                                    <Icon icon="heroicons-outline:download" />
                                  </button>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    onClick={() => handleDownload("watch-a7")}
                                    className={`${
                                      active ? "bg-primary-50 text-gray-900" : "text-gray-900"
                                    } group flex w-full items-center justify-between rounded-md px-2 py-2 text-sm`}
                                  >
                                    <span>iWatch 7</span>
                                    <Icon icon="heroicons-outline:download" />
                                  </button>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <button
                                    onClick={() => handleDownload("watch")}
                                    className={`${
                                      active ? "bg-primary-50 text-gray-900" : "text-gray-900"
                                    } group flex w-full items-center justify-between rounded-md px-2 py-2 text-sm`}
                                  >
                                    <span>Others (1:1)</span>
                                    <Icon icon="heroicons-outline:download" />
                                  </button>
                                )}
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    )}
                  </Menu.Item>
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
      <Toaster />
    </>
  );
}

export default CardDigitalGoodies;
