import { useMoralis } from "react-moralis";

/**
 * 
 * @param {func} authenticator - authenticate function from moralis SDK
 * @param {bool} authenticated - authentication status
 * @param {object} user - user object
 * @param {string} connector - "Metamask" or "TrustWallet"
 */
const handleLogin = async (moralis, connector) => {
    let currUser;
    switch (connector){
        case "Metamask":
            currUser = await moralis.authenticate({signingMessage: process.env.REACT_APP_CUSTOM_SIGNING_MESSAGE});
            if (!currUser){
                throw "Not Authenticated";
            }
            break;
        case "TrustWallet":
            console.log("Authenticating through trustwallet");
            currUser = await moralis.authenticate({signingMessage: process.env.REACT_APP_CUSTOM_SIGNING_MESSAGE, provider: "walletconnect"});
            if (!currUser){
                throw "Not Authenticated";
            }
            break;
        case "Phantom":
            console.log("Authenticating through Phantom Wallet");
            currUser = await moralis.authenticate({signingMessage: process.env.REACT_APP_CUSTOM_SIGNING_MESSAGE, type: "sol"});
            if (!currUser){
                throw "Not Authenticated";
            }
            break;
    }
};

/**
 * 
 * @param {func} onAuthenticated - onAuthenticated Callback
 * @param {func} onLoginFailed = onLoginFailed Callback
 * @param {func} onLogout - onLogout Callback
 * @param {func} onLogoutFailed - onLogoutFailed
 */
export const useLogin = () => {
    const { Moralis, isAuthenticated, user } = useMoralis();
    return { 
        Web3Login: (connector) => handleLogin(Moralis, connector),
        User: user,
        isMetamaskInstalled: window.ethereum !== undefined,
        isAuthenticated
    };
}

export default useLogin;